<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('notification.templateNotify')"
          :text="notificationContentCategoryName"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              color="success"
              dark
              style="float: right;"
              @click="createNewTemplateNotification"
            >
              <v-icon class="mr-2">mdi-text-box-plus-outline</v-icon>
              <span style="text-transform: none;">{{
                $t('templateEmail.createNew')
              }}</span>
            </v-btn>
            <v-btn
              color="error"
              dark
              style="float: right;"
              @click="onClose"
            >
              <v-icon class="mr-2">mdi-close</v-icon>
              <span style="text-transform: none;">{{
                $t('common.close')
              }}</span>
            </v-btn>
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="templateNotificationHeaders"
            :items="templateNotificationList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td
                v-for="(header, index) in templateNotificationHeaders"
                :key="header.value + index"
              >
                <div v-if="header.value == 'usedDefault'">
                  <v-tooltip
                    v-if="!item[header.value]"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="error"
                        dark
                        v-on="on">mdi-close-outline</v-icon>
                    </template>
                    <span>{{ $t('evoucher.state.inactive') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-else
                    top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="success"
                        dark
                        v-on="on">mdi-check-outline</v-icon>
                    </template>
                    <span>{{ $t('evoucher.state.active') }}</span>
                  </v-tooltip>
                </div>
                <div v-else-if="header.value == 'actions'">
                  <!-- Update template -->
                  <v-tooltip
                    v-if="!item['usedDefault']"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        color="indigo"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="useThisTemplate(item['id'])"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("templateEmail.usedThisTemplate") }}</span>
                  </v-tooltip>
                  <!-- Update template -->
                  <v-tooltip
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        color="primary"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="updateTemplateNotification(item['id'])"
                      >
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("common.update") }}</span>
                  </v-tooltip>
                  <!-- Delete template -->
                  <v-tooltip
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        color="error"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowModalConfirmDelete(item['id'])"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("common.delete") }}</span>
                  </v-tooltip>
                </div>
                <span v-else>{{ item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="paginate.totalPage"
      :current-page="paginate.currentPage"
      :row-per-page="paginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmDeleted"
    />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmModal from 'Components/ConfirmModal'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
export default {
  components: {
    ConfirmModal
  },
  data: () => ({
    templateNotificationHeaders: [
      { sortable: false, text: 'common.nonumber', value: 'stt' },
      { sortable: false, text: 'templateEmail.name', value: 'name' },
      { sortable: false, text: 'templateEmail.usedDefault', value: 'usedDefault' },
      { sortable: false, text: 'common.actions', value: 'actions' }
    ],
    templateNotificationList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    confirmModalTitle: '',
    templateEmailId: null,
    isLoading: false,
    notificationContentCategoryName: ''
  }),
  computed: {
    ...mapGetters([
      'NOTIFICATION_CONTENT_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    NOTIFICATION_CONTENT_DATA () {
      let res = this.NOTIFICATION_CONTENT_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let templateNotificationData = res.results
      this.templateNotificationList = []
      for (let i = 0, size = templateNotificationData.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: templateNotificationData[i].id,
          name: templateNotificationData[i].title,
          category: null,
          usedDefault: templateNotificationData[i].used_default
        //   supplier: templateNotificationData[i].entity.name
        }
        this.templateNotificationList.push(obj)
      }
    }
  },
  created () {
    this.getNotificationContentList()
    this.notificationContentCategoryName = this.$route.query.name
  },
  methods: {
    /**
     * Check admin role
     */
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Show modal confirm delete
     */
    onShowModalConfirmDelete: function (id) {
      this.templateEmailId = id
      this.confirmModalTitle = this.$t('notification.modal.confirmDeleteTitle')
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Confirm delete
     */
    onConfirmDeleted: function () {
      this.DELETE_NOTIFICATION_CONTENT({ id: this.templateEmailId }).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getNotificationContentList()
        }.bind(this)
      )
    },
    /**
     * Use this template
     */
    useThisTemplate: function (id) {
      let notificationContentCategoryId = this.$route.params.id
      let filter = {
        usedTemplate: true,
        categoryId: notificationContentCategoryId
      }
      this.UPDATE_NOTIFICATION_CONTENT({ id: id, ...filter }).then(
        function () {
          this.getNotificationContentList()
        }.bind(this)
      )
    },
    /**
     * Update template email
     */
    updateTemplateNotification: function (id) {
      let notificationContentCategoryId = this.$route.params.id
      this.$router.push({
        path: `/update-notification-content/${id}/${notificationContentCategoryId}`,
        params: {
          templateId: id,
          categoryId: notificationContentCategoryId
        }
      })
    },
    /**
     * Go back
     */
    onClose: function () {
      window.history.back()
    },
    /**
     * Create new template email
     */
    createNewTemplateNotification: function () {
      let categoryId = this.$route.params.id
      this.$router.push({
        'path': `/create-notification-content/${categoryId}`,
        params: {
          categoryId: categoryId
        }
      })
    },
    /**
     * Get template email list
     */
    getNotificationContentList: function () {
      let notificationContentId = this.$route.params.id
      let filter = {
        params: {
          page: this.paginate.currentPage,
          categoryId: notificationContentId
        }
      }
      this.isLoading = true
      this.GET_NOTIFICATION_CONTENT(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getNotificationContentList()
    },
    ...mapActions([
      'GET_NOTIFICATION_CONTENT',
      'UPDATE_NOTIFICATION_CONTENT',
      'DELETE_NOTIFICATION_CONTENT',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style></style>
